import { useLocation } from "@reach/router";
import { graphql, PageProps, useStaticQuery } from "gatsby";
import React, { useState } from "react";
import Course from "../components/Course";
import Layout from "../components/layout";
import Seo from "../components/seo";



const CoursePage: React.FC<PageProps> = () => {
  const location = useLocation()

  return (
    <>
      <Seo title="Reiki Grad Wien Kurs" />
      <Layout location={location}>
        <Course />
      </Layout>
    </>
  )
}

export default CoursePage
